<template>
  <main id="main-content"
    :class="classes"
    :style="backgroundColorStyle"
  >
    <Toast
      v-for="toast in toasts"
      :key="toast._uid"
      v-editable="data"
      :cookie-id="toast._uid"
      v-bind="toast"
    />

    <component
      :is="component"
      :id="id"
      :url="url"
      :language-code="$store.state.frontend.currentLanguageCode"
      :data="data"
    />

    <portal-target name="underscore" slim />
    <portal-target name="sticky" slim />
  </main>
</template>
<script>
import Default from '@made-people/centra-storyblok-nuxt-shared/lib/pages/_'
import {mapGetters} from "vuex";

export default {
  mixins: [Default],
  head() {
    return {
      htmlAttrs: {
        lang: this.$store.state.frontend.currentLanguageCode,
      },
    }
  },
  computed: {
    ...mapGetters({
      settings: 'storyblok/settings',
    }),
    page() {
      if (this.component === 'storyblok')
        return this.$store.getters['storyblok/getStoryById'](this.id)
      return null
    },
    pageBackgroundColor() {
      return this.page?.content?.backgroundColor
    },
    pageBackgroundColorHex() {
      return this.settings?.defaultBackgroundColorHex
    },
    isPageBackgroundColorDefault() {
      return this.pageBackgroundColor === 'grey-lighter'
    },
    // only apply hex color background if page has default background color
    backgroundColorStyle() {
      if (this.isPageBackgroundColorDefault && this.pageBackgroundColorHex)
        return {
          backgroundColor: this.pageBackgroundColorHex,
        }
      return {}
    },
  },
  serverPrefetch() {
    // This is allowed if we're behind fastly or cloudflare
    if (this.$config.pageResponseCacheTtl) {
      this.$ssrContext.res.setHeader(
        'cache-control',
        `public, max-age=${this.$config.pageResponseCacheTtl}, stale-while-revalidate=600, stale-while-error=86400`
      )
    }
  },
}
</script>
